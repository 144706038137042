// @ts-nocheck
import axios from 'axios';

import env from '../environtments.js';

const URL_API = env.API_URL

// METHOD , TABLE NAME / * , DATA 

export const getinApi = async (method,table_ids,data = null ) => {
  switch (method) {
    case 'get': 
      return await axios.get(URL_API+table_ids).then(res => {
        return res.data;
      }).catch(err => {
        return {status:'Error'};
      }); 
    case 'post': 
      return await axios.post(URL_API+table_ids, data).then(res => {
        return res.data;
      }).catch(err => {
        return {status:'Error'};
      });
    case 'put': 
      return await axios.put(URL_API+table_ids, data).then(res => {
        return res.data;
      }).catch(err => {
        return {status:'Error'};
      });
    case 'delete': 
      return await axios.delete(URL_API+table_ids).then(res => {
        return res.data;
      }).catch(err => {
        return {status:'Error'};
      });
    default: break;
  }
}