import _ from 'lodash';
import React, { useState, Fragment } from 'react';
// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'

export const Upload = ({ simple, filesg, setLocalFiles, group, close}) => {
  	const { t, i18n } = useTranslation();

  const [dragOver, setDragOver] = useState(false);
  const [fileDropError, setFileDropError] = useState('');


  const onDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };
  const onDragLeave = () => setDragOver(false);

  const onDrop = (e) => {
    e.preventDefault();
    setDragOver(false);
    const selectedFile = e.dataTransfer.files;
    handleChangeDrag(selectedFile)
    //  setFile(selectedFile);
  };

  const handleChangeDrag = async (files) => {
    // console.log(files);
    let local_files = [...filesg];
    _.forEach(files, file => {
      local_files.push(file)
      setLocalFiles(group, local_files);
    });

    close(true)

  }

  const handleChange = async e => {

    // console.log(e);

    console.log(e.target.files);
    let { files } = e.target;
    let local_files = [...filesg];

    _.forEach(files, file => {
      local_files.push(file)
      setLocalFiles(group, local_files);
    });

    close(true)

  }

  const RemoveFile = async name => {
    let files_local = [...filesg];
    let resulst_file = files_local.filter(file => file.name !== name)
    // console.log(resulst_file);
    setLocalFiles(group, resulst_file);
  }

  return (
    <Fragment>
      <div className="attach">
        {filesg.length > 1 ? (
          <div className="scroller">
            <i className="icon icon_top"></i>
            <span className="line-color"></span>
            <i className="icon icon_down"></i>
          </div>
        ) : <div></div>
        }
          <div className="box-list">
            {
              filesg.length > 0 ? (
                filesg.map((uploadedImg, index) => (
                  <div key={index} className="chips-uploads" >
                    <span className="chip-upload">
                      {(uploadedImg.type === 'image/jpeg' || uploadedImg.type === 'image/jpg' || uploadedImg.type === 'image/png')
                        ? <i className="icon icon_image"></i>
                        : <i className="icon icon_file"></i>}
                      <span>{uploadedImg.name}</span>
                      <i className="material-icons close" onClick={() => RemoveFile(uploadedImg.name)}>X</i></span>
                  </div>
                ))
              ) : <p>No files attached</p>
            }
          </div>
      </div>
      <input multiple className="input-file" type="file" id='fiels-upload' onChange={handleChange} />
      <label className='drag inp-files drag_perfil' htmlFor="fiels-upload"
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        style={{ borderColor: `${dragOver ? " yellowgreen" : ""}`, color: `${dragOver ? " yellowgreen" : ""}` }}
      >
        <i className='icon icon-cloud_up'></i>
        <p>{t('drag.label')} </p>
      </label>
      <label htmlFor="fiels-upload" className={'btn_b inp-files '+ t('lang.label')} >
        <i className='icon icon-up'></i>
        {!simple ? 'Adjuntar los archivos desde el computador' : t('choose.label')}
      </label>
    </Fragment>
  )
}

