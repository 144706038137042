import React from 'react'
import { Switch, Route } from 'react-router-dom'

// Views 
import View from './views/View'

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={View} />
    </Switch>
  )
}