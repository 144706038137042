import React, { useState, useEffect } from 'react';
import {Upload} from './Uplaoders2'
import $ from 'jquery';
import { Fragment } from 'react/cjs/react.production.min';
// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'

const ModalUplfiles = ({ open, close, setLocalFiles, files, group }) => {

  const { t, i18n } = useTranslation();

  const closeInside = () => {
    $('.modal_upload').css('display', 'none');
    close(true)
  }

  const closeInsideTrue = () => {
    $('.modal_upload').css('display', 'none');
    close(true)
  }

  useEffect(() => {
    // console.log(open);
    if (open) {
      $('.modal_upload').css('display', 'flex');
    }
  }, [open])

  // useEffect(() => {
  //   console.log(group);

  // }, [group])


  return (
    <Fragment>
      <div>
       Hola {}
      </div>
      {open &&
        <div className='modali modal_upload'>
          <div className='upload animate__animated animate__bounceIn'>
            <h5 className='titulo_modal'>{t('add_fil.label')}</h5>
            <Upload
              simple={true}
              filesg={files}
              setLocalFiles={setLocalFiles}
              group={group}
              close={closeInsideTrue}
            />
            <div className={'actions_upload '+t('lang.label')} >
              <button className='btn_b cancelar' onClick={closeInside}>{t('cancel.label')}</button>
              <button className='btn_b continuar' onClick={closeInsideTrue} >
                {t('continue.label')}
              </button>
            </div>
          </div>
        </div>
      }
    </Fragment>
  );
};

export default ModalUplfiles;
