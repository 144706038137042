import _ from 'lodash';
import React, { useEffect }from 'react';
import { Placeholder } from 'react-select/lib/animated';

// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'

export const Upload = ({ filesg, setLocalFiles, placeholder, group, ShutModal }) => {
  const { t, i18n } = useTranslation();

  const handleChange = async e => {

    console.log(e.target.files);

    let { files } = e.target;


    let local_files = [...filesg];

   
    _.forEach(files, file => {
      local_files.push(file)
      setLocalFiles(group, local_files);
    });
    

  }

  const RemoveFile = async name => {
    let files_local = [...filesg ];
    let resulst_file = files_local.filter( file => file.name !== name )
    console.log(resulst_file);
    setLocalFiles(group, resulst_file, name );
  }
  
  const shuter = () => {
    ShutModal(group)
  }

  // useEffect(() => {
  //   if (group) {
  //     console.log(group);
  //   }
  // }, [group])

  return (
    <div className="attach">
      {filesg.length > 1 ? (
        <div className="scroller">        
          <i className="icon icon_top"></i>
          <span className="line-color"></span>
          <i className="icon icon_down"></i>
        </div>
          ) : <div></div> 
      }
      <div className="box-list">
        {
          filesg.length > 0 ? (
            filesg.map((uploadedImg, index) => (
              <div key={index} className="chips-uploads" >
                <span className="chip-upload">
                  {(uploadedImg.type === 'image/jpeg' || uploadedImg.type === 'image/jpg' || uploadedImg.type === 'image/png')
                    ? <i className="icon icon_image"></i>
                    : <i className="icon icon_file"></i>}
                  <span>{uploadedImg.name}</span>
                  <i className="material-icons close" onClick={() => RemoveFile(uploadedImg.name)}>X</i></span>
              </div>
            ))
          ) : <p>{placeholder}</p>
        }
      </div>
      <input multiple className="input-file" type="file" id={"fiels-upload-"+group} onChange={handleChange} />
      <label className="button-file" onClick={shuter}>{t('add_files.label')}<i className="icon icon_add"></i></label>
    </div>
  )
}

