// @ts-nocheck
import React, { useState, useEffect, Fragment } from 'react';
// Libraries 
import $ from 'jquery';
import moment from "moment";
//img
import img_thanks from '../assets/images/Forms-amico.svg'
// Components 
import SignatureCanvas from 'react-signature-canvas';
import Select from 'react-select';
import Modal from 'react-awesome-modal';
import Policies from '../components/policies';
import Chips from 'react-chips'
import Chip from '../components/Chip'
import ModalUplfiles  from '../components/ModalUploader'
import { Upload }  from '../components/Uploaders'

// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'

// Services 
import { getinApi } from '../services/api'
import Axios from 'axios';

const initialStatesMarkedplaces = [ 
  {label:'ahip (medicare)', vinculo:'https://www.ahipmedicaretraining.com/page/login'}, 
  {label:'california', vinculo:'https://www.coveredca.com/agents/become-an-agent-for-small-business/training-and-certification-CCSB-agents/'}, 
  {label:'colorado', vinculo:'https://connectforhealthco.com/for-certified-brokers/'}, 
  {label:'connecticut', vinculo:'mailto:Brokerregistration.AHCT@ct.gov'}, 
  {label:'kentuky', vinculo:'https://ssoexternal.chfs.ky.gov/'},
  {label:'maryland', vinculo:'https://mhbe.force.com/training/CustomCommunityLogin'}, 
  {label:'minnesota', vinculo:'https://www.mnsure.org/assister-central/broker-one-stop/cert-recert/training-access/index.jsp'},
  {label:'new jersey', vinculo:'https://nj.gov/getcoverednj/home/assisters_ba.shtml'},
  {label:'new mexico', vinculo:'https://www.bewellnm.com/getting-started/agents-brokers/'}, 
  {label:'pennsylvania', vinculo:'https://agency.pennie.com/brokercertification/#brokers-recertification'}, 
  {label:'washinton', vinculo:'https://www.wahealthplanfinder.org/content/wahbe/global/en/partners/insurance-brokers.html'}]

function View () {

	const { t, i18n } = useTranslation();
	const [url, setUrl] = useState(null);

	const [open, setOpen] = useState(false);
  const [group, setGroup] = useState(false);
  const [step, setStep] = useState(1);
  const [checks, setChecks] = useState([]);
  const [checksM, setChecksM] = useState([]);



	const changeLanguage = (lang) => {
		i18n.changeLanguage(lang);
		$('.bt').removeClass('active');
		$('.' + lang).addClass('active');
		localStorage.setItem('lng', lang);
	};
  // Atributes 
  const [inputs, setInputs] = useState({
    fullname: '',
    dbo: '',
    adress: '',
    state: '',
    email: '',
    phone: '',
    ssn: '',
    npn: '',
    ffm_id: '',
    eo_carrier: '',
    eo_pol: '',
    eo_date: '',
    lic_state: '',
    lic_num: '',
    licensed_states: '',
    files: '',
    authorization: '',
    apli_date: '',
    signature_digital: '',
    signature_type: '',
    city: ''
  })

  let [dates, setDatess] = useState({
    date_month: '',
    date_day: '',
    date_year: ''
  })

  let [statesMarket, setStatesMarket] = useState(initialStatesMarkedplaces)
  let [states, setStates] = useState([])
  let [files, setFiles] = useState({
    files1: [],
    files2: [],
    files3: [],
    files4: [],
    files5: [],
    files6: []
  })
  let [files_real, setFilesR] = useState([])
  let [filesAppScript, setFilesAppScript] = useState([]);
  let [suggestions, setSugest] = useState([])
  let [cities, setCities] = useState([])
  let [selectcity, setSelCity] = useState()
  let [selectstate, setSelState] = useState()
  let [selectstate2, setSelState2] = useState()
  const [terms, setTerms] = useState(false)
  const [visible, setvisible] = useState(false)
  const [stLicense, setStLicence] = useState([])
  const [stMarket, setStMarket] = useState([])
  const [sended, setSended] = useState(false)

  let sigPad = {}
    useEffect(() => {
    getStates()
    getSign()
    setDates()
    }, [])
  // Functions 
  useEffect(() => {
    getStates()
    getSign()
    setDates()

    // efect chips inside camp 16
    $(document).ready(function () {

      // effect fill inputs
      $('.input').on('change', function () {
      
          if ($(this).val() !== '') {
            $(this).parent().addClass('group-fill');
            $(this).addClass('fill');
          } else {
            $(this).removeClass('fill');
            $(this).parent().removeClass('group-fill');
          }
        
      });

      $('.input').on('focus', function () {
          $(this).parent().addClass('group-fill');
          $(this).on('blur', function () {
            if (!$(this).hasClass('fill')) {
              $(this).parent().removeClass('group-fill');
            }
          });
      });

      // efect label camp 16
      $('.camp16 input').on('focus', function () {
        $('.camp16 label').addClass('activ');
        $('.box-moved').addClass('active');
        $('.gray-box').addClass('focus');
      });

      $('.camp16 input').on('blur', function () {
        if ($('.camp16 .chips').find('.chip').length <= 0 && $('#react-autowhatever-1').children().length === 0 && $(this).val() === ''  ) {
          $('.camp16 label').removeClass('activ');
          $('.box-moved').removeClass('active');
          $('.gray-box').removeClass('focus');
        }
      });

      $('.camp4 .react-select__input input').on('focus', function () {
        $('.camp4').addClass('group-fill')
        $('.camp4 label').addClass('active')
      });
      $('.camp5 .react-select__input input').on('focus', function () {
        $('.camp5').addClass('group-fill')
        $('.camp5 label').addClass('active')
      });
      $('.camp14 .react-select__input input').on('focus', function () {
        $('.camp14').addClass('group-fill')
        $('.camp14 label').addClass('active')
      });

      $('.camp4 .react-select__input input').on('blur', function () {
        if (!$('.camp4 .react-select__single-value').html()){
          $('.camp4').removeClass('group-fill')
          $('.camp4 label').removeClass('active')
        }
      });
      $('.camp5 .react-select__input input').on('blur', function () {
        if (!$('.camp5 .react-select__single-value').html()) {
          $('.camp5').removeClass('group-fill')
          $('.camp5 label').removeClass('active')
        }
      });
      $('.camp14 .react-select__input input').on('blur', function () {
        if (!$('.camp14 .react-select__single-value').html()) {
          $('.camp14').removeClass('group-fill')
          $('.camp14 label').removeClass('active')
        }
      });
      $('.uncertified').on('click', function () {
        $('.uncertified_buttons').toggleClass('active')
      })

    });
  }, [step])

  const openClick= (step)=>{
    if(step==1){
      $('.step').removeClass('active')
      $('.step'+step).addClass('active')
    }
        changeStep(step)
  }

  const changeStep =  (st)=>{
    if((st > 1 && st == 2)||st === 2){
      let validations = 0;
      if ((inputs.fullname).trim() === '') {
        $('#fullname').addClass('invalide')
        validations = 1;
      }
      if ((inputs.phone).trim() === '') {
        $('#phone').addClass('invalide')
        validations = 1;
      }

      let date1 = $('.camp2 .input.datepicker').val()      
      if ((date1)=== '') {
        $('#dbo').addClass('invalide')
        validations = 1;
      }
      let bdo = ''
      if (date1 !== '') {
        bdo = Date.parse(date1)
        // console.log(bdo);
        // console.log(st);
        // console.log(date1);
          setInputs({ ...inputs, dbo: moment(bdo).format('YYYY-MM-DD') })
      }

      if ((inputs.email).trim() === '') {
        $('#email').addClass('invalide')
        validations = 1;
      }
      if (validations) {
        $('.step1').addClass('invalide')
        return
      }
        $('.step1').removeClass('invalide')
        $('.step').removeClass('active')
        $('.step2').addClass('active')
    }
    
    if(st > 2 && st == 3){
      let validations = 0;

      // if ((inputs.eo_carrier).trim() === '') {
      //   $('#eo_carrier').addClass('invalide')
      //   validations = 1;
      // }
      // if ((inputs.eo_pol).trim() === '') {
      //   $('#eo_pol').addClass('invalide')
      //   validations = 1;
      // }
      let date13 = $('.camp13 .input.datepicker').val()      
      if ((date13)=== '') {
        $('#eo_date').addClass('invalide')
        validations = 1;
      }
      let eo_d = ''
      if (date13 !== '') {
        eo_d = Date.parse(date13)
          // console.log(moment(eo_d).format('YYYY-MM-DD'));
        setInputs({ ...inputs, eo_date: moment(eo_d).format('YYYY-MM-DD') })
      }

      if (validations) {
        $('.step2').addClass('invalide')
        return
      }
        $('.step2').removeClass('invalide')
        $('.step').removeClass('active')
        $('.step3').addClass('active')
    }

    if(st > 3){
      let validations = 0;
      if (validations) {
        $('.step3').addClass('invalide')
        return
      }
        $('.step3').removeClass('invalide')
        $('.step').removeClass('active')
        $('.step4').addClass('active')
    }


    setStep(st)
    setTimeout(() => {
      const event =new Event('getPicker')
      document.dispatchEvent(event)
    }, 500);
  }

  const setDates = () => {
    var check = moment(new Date());
    var dater = {
      month: check.format('MM'),
      day: check.format('DD'),
      year: check.format('YYYY')
    }
    setDatess({ ...dates, date_month: dater.month, date_day: dater.day, date_year: dater.year })
    let date_input = dater.year + '-' + dater.month + '-' + dater.day
    setInputs({ ...inputs, apli_date: check })
  }
  const setSelectState = e => {
    $('.camp4').addClass('group-fill')
    $('.camp4 label').addClass('active')
    clearInvalid()
    setSelState(e)
    setSelCity(null)
    getCities(e.value)
    setInputs({ ...inputs, state: e.label, city_id: '' })
  }
  const setSelectState2 = e => {
    $('.camp14').addClass('group-fill')
    $('.camp14 label').addClass('active')
    clearInvalid()
    setSelState2(e)
    setInputs({ ...inputs, lic_state: e.label  })
  }
  const setSelectCity = e => {
    $('.camp5').addClass('group-fill')
    $('.camp5 label').addClass('active')
    clearInvalid()
    setSelCity(e)
    setInputs({ ...inputs, city: e.value })
  }
  const getStates = async () => {
    
    await getinApi('get', 'state')
      .then(async(res) => {
        let states = [];
        let suggestiona = [];
        // console.log(res);
        await Promise.all(res.map(async (state) => {
          let stat = {
            value: state.id,
            label: state.state
          }
          states.push(stat);
          suggestiona.push(state.state);
        }));
        setStates(states)
        setSugest(suggestiona)
      })
      .catch((err) => console.log(err))
  }
  const getCities = async (id_state) => {
    await getinApi('get', `citybystate/${id_state}`)
      .then(async(res) => {
        let cities = [];
        await Promise.all(res.map(async (city) => {
          if (cities.length > 0 ) {
            let share = cities.filter((ciry) => {
              return ciry.label === city.name;
            })
            if (share.length <= 0) {
              let cit = {
                value: city.id,
                label: city.name
              }
              cities.push(cit);
            }
          }else{
            let cit = {
              value: city.id,
              label: city.name
            }
            cities.push(cit);
          }
        
        }));
        setCities(cities)
      })
      .catch((err) => console.log(err))
  }
  const setDatER = e => {
    // console.log('cambiar');
    // console.log(e.target);
  }
  const setInput = e => {
    clearInvalid()
    setInputs({ ...inputs, [e.target.name]: e.target.value })
  }
  const clearInvalid = () => {
    $('body').find('.invalide').removeClass('invalide')
  }
  const getFile = e => {
    clearInvalid()
    setInputs({ ...inputs, signature_digital: '', signature_type: '' })
    $('#fileinput').trigger('click');
    $('.opt-1').show();
    $('.brns .brn').removeClass('active');
    $('.brns .brn.btn3').addClass('active');
    $('.opt-2').hide();
    $('.opt-3').hide();
  }
  const getText = e => {
    clearInvalid()
    setInputs({ ...inputs, signature_digital: $('#text_sign').val(), signature_type: 'Text' })
    $('.brns .brn').removeClass('active');
    $('.brns .brn.btn1').addClass('active');
    $('.opt-1').hide();
    $('.opt-2').hide();
    $('.opt-3').show();
  }
  const getSign = e => {
    clearInvalid()
    setInputs({ ...inputs, signature_type: 'Sign' })
    trim()
    $('.brns .brn').removeClass('active');
    $('.brns .brn.btn2').addClass('active');
    $('.opt-1').hide();
    $('.opt-2').show();
    $('.opt-3').hide();
  }
  const clearSig = () => {
    clearInvalid()
    setInputs({ ...inputs, signature_digital: '', signature_type: 'Sign' })
    sigPad.clear();
  }
  const trim = () => {
    clearInvalid()
    if (!sended && step == 4) {
      setInputs({ ...inputs, signature_digital: sigPad.getTrimmedCanvas().toDataURL(), signature_type: 'Sign' })
    }
  }
  const getImage = (e) => {
    clearInvalid()
    if (e.target.files[0]) {
      setInputs({ ...inputs, signature_type: 'Image' })
      var reader = new FileReader();
      var f = e.target.files[0]
      reader.onloadend = function () {
        setInputs({ ...inputs, signature_digital: reader.result, signature_type: 'Image' })
      }
      reader.readAsDataURL(f);
    }
  }
  const openModal = () => {
    $('.terminos .checkmark').removeClass('invalide-all')
    setvisible(true)
    setTerms(!terms)
  }
  const closeModal = () => {
    setvisible(false)
  }
  const inserChip = function(e){
    setStLicence([...stLicense, e.target.value])
    $('input:checked').each(function () {
      setChecks([...checks, e.target.value])
    })
  }
  useEffect(() => {
    let sttes = [...stLicense];
    setInputs({ ...inputs, licensed_states: JSON.stringify(sttes) })
  }, [stLicense])
  const inserSttMArk = function(e) {
    setStMarket([...stMarket, e.target.value])
      $('input:checked').each(function () {
      setChecksM([...checksM, e.target.value])
      })
  }
  useEffect(() => {
    let st_m = [...stMarket];
    setInputs({ ...inputs, market_states: JSON.stringify(st_m) })
  }, [stMarket])
  const converteMonth = (date) => {
    let number_month;
    switch (date) {
      case 'Jan ': number_month = '01'; break;
      case 'Feb ': number_month = '02'; break;
      case 'Mar ': number_month = '03'; break;
      case 'Apr ': number_month = '04'; break;
      case 'May ': number_month = '05'; break;
      case 'Jun ': number_month = '06'; break;
      case 'Jul ': number_month = '07'; break;
      case 'Aug ': number_month = '08'; break;
      case 'Sep ': number_month = '09'; break;
      case 'Oct ': number_month = '10'; break;
      case 'Nov ': number_month = '11'; break;
      case 'Dec ': number_month = '12'; break;
    }
    return number_month
  }
  const setLocalFiles = async (name,filess,remove = null) => {
    setFiles({ ...files, [name]: filess})
    
    let fiels_local = [...files_real]

    var reader = new FileReader();
    reader.onloadend = function () {
      filesAppScript.push(reader.result)
    }

    if (remove) {
      fiels_local = fiels_local.filter(file => file.name !== remove)
    }else{
      await Promise.all(filess.map(async (file) => {
        reader.readAsDataURL(file)
        fiels_local.push(file)
      }));
    }

    setFilesR(fiels_local)

 
  }
  const uploadsFiles = async () => {
    let formData = new FormData();
    
    await Promise.all(
      files_real.map(async (file) => {
        formData.append('files', file);
      }),
    );
    
    let urls 
    await getinApi('post', 'uploadFiles', formData ).then((res) => {
      // console.log(res);
      if (res.status === 'Success') {
        urls = res.url
      }
    }).catch((err) => console.log(err))


    setInputs({ ...inputs, files: urls })
    return urls
  }
  const ShutModal = async (grup) => {
    // console.log(grup);
    setGroup(grup)
    setOpen(true)
  }
  const close = async () => {
    setOpen(false)
  }
  const totop =  () => {
    $('.camp13').attr('style','z-index:8');
  }

  const submitInputs = async () => {
    
    let validations = 0;
    
    if ((inputs.authorization).trim() === '') {
      $('#authorization').addClass('invalide')
      validations = 1;
    }
    if (!terms) {
      $('.terminos .checkmark').addClass('invalide-all')
      validations = 1;
    }
    if ((inputs.signature_digital).trim() === '') {
      $('#firma-id').addClass('invalide')
      $('#sig-buton-cap').addClass('invalide-all')
      validations = 1;
    }
    if ((inputs.signature_type).trim() === '') {
      $('#firma-id').addClass('invalide')
      validations = 1;
    }

    if (validations) {
      $('.step4').addClass('invalide');
      return
    }
    $('.step4').removeClass('invalide')

    let local_files = await uploadsFiles();
    let inputs_local = { ...inputs, files: local_files, apli_date: moment(new Date()).format('YYYY-MM-DD') }

    let formatoAppScriptSheets = {...inputs_local}

    formatoAppScriptSheets.files = filesAppScript
    formatoAppScriptSheets.licensed_states = formatoAppScriptSheets.licensed_states.toString()
    formatoAppScriptSheets.npn = inputs.npn
    
    // console.log({formatoAppScriptSheets})

    Axios.post(
      `https://script.google.com/macros/s/AKfycbwIhmF4Je2dCCDFfHPm9SAEcX2u43VoOfyB-44O4iMbaiLlUoHS1rmFhidpfhKNOMh-/exec`,
      formatoAppScriptSheets,
      {
        headers: {
          'Content-Type': 'text/plain'
        }
      }
      )

    await getinApi('post', 'agent', inputs_local).then((res) => {
      // console.log(res); 
      setSended(true); 
    }).catch((err) => console.log(err))
    setSended(true); 
    // console.log(inputs);
  }

  // useEffect(() => {
  //   console.log(files);
  // }, [files])

  // useEffect(() => {
  //   console.log(files_real);
  // }, [files_real])

  // useEffect(() => {
  //   console.log(inputs);
  // }, [inputs])

  return (
  <div className="contain">
    <div className="mod">
      <div className="back1">
        <img src="./assets/images/back1.svg" alt="" />
      </div>
      <div className="back2">
        <img src="./assets/images/back2.svg" alt="" />
      </div>
      <div className="logo">
        <img src="./assets/images/logo.svg" alt="logo" />
      </div>
    </div>

    {(localStorage.getItem('lng')==='es') ?
    <div className='langs'>
  
      <div className='botones'>
        <button
          className={
            localStorage.getItem('lng') === 'es' ? 'bt es active' : 'bt es'
          }
            onClick={() => changeLanguage('es')}
          type='button'
        >
          <div className='flag flag-es'></div>
          <span>ESP</span>
        </button>
        <button
          className={
            localStorage.getItem('lng') === 'en' ? 'bt en active' : 'bt en'
          }
          onClick={() => changeLanguage('en')}
          type='button'
        >
          <div className='flag flag-en'></div>
          <span>ING</span>
        </button>
      </div>

      </div>:
    <div className='langs'>
  
      <div className='botones'>
        <button
          className={
            localStorage.getItem('lng') === 'es' ? 'bt es active' : 'bt es'
          }
            onClick={() => changeLanguage('es')}
          type='button'
        >
          <div className='flag flag-es'></div>
          <span>SPA</span>
        </button>
        <button
          className={
            localStorage.getItem('lng') === 'en' ? 'bt en active' : 'bt en'
          }
          onClick={() => changeLanguage('en')}
          type='button'
        >
          <div className='flag flag-en'></div>
          <span>ENG</span>
        </button>
      </div>

      </div>
    }
        <a
					className='bubble_what'
					href='https://bit.ly/WEB-EMI-AGENTS'
          target='_blank'
          rel="noopener noreferrer"
				>
					<i className='icon icon_what-share'></i>
				</a>

    <div className={!sended ? 'form-agent' : 'form-agent sended'}>
      { sended 
        ? <div className="enviado">
            <div className="doble">
              <div className="">
                <h2>{t('thanks.label')}</h2>
                <span className="subtitle">{t('th_p.label')}</span>
                <a className="button leave" href="https://emilan.co" >{t('ok.label')}</a>
              </div>
              <img src={img_thanks}></img>
            </div>
          </div> 
      : <Fragment>
          <div className="steps">
            <div className="step step1 active" onClick={()=>openClick(1)}><i className="icon icon-step1"></i></div>
            <div className="step step2 " onClick={()=>openClick(2)}><i className="icon icon-step2"></i></div>
            <div className="step step3 " onClick={()=>openClick(3)}><i className="icon icon-step3"></i></div>
            <div className="step step4 " onClick={()=>openClick(4)}><i className="icon icon-step4"></i></div>
          </div>
          <div className="perfil">
            <img src="./assets/images/user.svg" alt="user" />
          </div>
          <h2>{t('title.label')}</h2>
          {step === 1 && 
            <div className="table box bo1 open animate__animated animate__fadeIn">
              <h5 className='subtitle'>1. {t('personal_info.label')}</h5>
              <div className="row">
                <div className={inputs.fullname !== ''? "input-field  camp1 group-fill":"input-field  camp1"} id="fullname">
                  <input id="camp1" type="text" className="input full_name" name="fullname" onChange={setInput} defaultValue={inputs.fullname}/>
                  <label htmlFor="camp1" className={inputs.fullname !== ''?'active':''}>{t('name.label')}</label>
                </div>
                <div className={inputs.dbo!==''?"input-field camp2 group-fill":"input-field camp2"} id="dbo">
                  <input className="input datepicker" type="text" name="dbo" id="camp2" onChange={() => setDatER(this)} defaultValue={inputs.dbo}/>
                  <label htmlFor="camp2" className={inputs.dbo !== ''?'active':''}>{t('date_birt.label')}</label>
                  <span className="flechas">
                    <img src="./assets/images/arrow-up.svg" alt="up" />
                    <img src="./assets/images/arrow-down.svg" alt="dw" />
                  </span>
                </div>
              </div>
              <div className="row">
                <div className={inputs.adress !== ''? "input-field  camp3 group-fill":"input-field  camp3"}>
                  <input className="input  address" type="text" name="adress" id="camp3" onChange={setInput}  defaultValue={inputs.adress}/>
                  <label htmlFor="camp3" className={inputs.adress !== ''?'active':''}>{t('address.label')}</label>
                </div>
                <div className={inputs.state !== ''? "input-field  camp4 group-fill":"input-field  camp4"}>
                  <Select className='input inp-ciudad slect-tag' id="inp-state" classNamePrefix="react-select" options={states} value={selectstate} placeholder=" " onChange={setSelectState}  defaultValue={inputs.state}/>
                  <label htmlFor="camp4" className={inputs.state !== ''?'active':''}>{t('state.label')}</label>
                </div>
                <div className={inputs.city !== ''? "input-field camp5 group-fill":"input-field camp5"} >
                  <Select className='input inp-ciudad slect-tag' id="inp-ciudad" classNamePrefix="react-select" options={cities} value={selectcity} placeholder=" " onChange={setSelectCity}  defaultValue={inputs.city_id}/>
                  <label htmlFor="camp5" className={inputs.city !== ''?'active':''}>{t('city.label')}</label>
                </div>
              </div>
              <div className="row">
                <div className={inputs.email !== ''? "input-field  camp6 group-fill":"input-field  camp6"}id="email">
                  <input className="input mail" type="email" name="email" id="camp6"
                    pattern=" ^[a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$" title="enter a valid email"
                    required onChange={setInput}  defaultValue={inputs.email}/>
                  <label htmlFor="camp6" className={inputs.email !== ''?'active':''}>{t('email.label')}</label>
                </div>
                <div className={inputs.phone !== ''? "input-field  camp7 group-fill":"input-field  camp7"} id="phone" >
                  <input className="input phone" type="tel" name="phone" id="camp7" onChange={setInput} defaultValue={inputs.phone}/>
                  <label htmlFor="camp7" className={inputs.phone !== ''?'active':''}>{t('telephone.label')}</label>
                </div>
              </div>
              <div className="row">
                <div className={inputs.ssn !== ''? "input-field  camp8 group-fill":"input-field  camp8"}>
                  <input className="input ssn" type="text" name="ssn" id="camp8" pattern="^[0-9]{3,3}-[0-9]{2,2}-[0-9]{4,4}$" onChange={setInput}  defaultValue={inputs.ssn}/>
                  <label htmlFor="camp8" className={inputs.ssn !== ''?'active':''}>{t('num_ss.label')}</label>
                </div>
              </div>
                      <button className="button2" id='boton1' onClick={() => changeStep(2)}>{t('next.label')}</button>
            </div>
          }
          {step === 2 && 
            <div className="box box2 box-moved bo2 animate__animated animate__fadeIn">
              <h5 className='subtitle'>2. {t('data_lic.label')}</h5>
              <div className="row">
                <div className={inputs.npn !== ''? "input-field  camp9 group-fill":"input-field  camp9"}>
                  <input className="input npn" type="number" name="npn" id="camp9" pattern="^[0-9]{5,9}$" onChange={setInput}  defaultValue={inputs.npn}/>
                  <label htmlFor="camp9" className={inputs.npn !== ''?'active':''}>{t('npn.label')}</label>
                </div>
                <div className={inputs.ffm_id !== ''? "input-field  camp10 group-fill":"input-field  camp10"}>
                  <input className="input ffm" type="text" name="ffm_id" id="camp10" onChange={setInput} defaultValue={inputs.ffm_id}/>
                  <label htmlFor="camp10" className={inputs.ffm_id !== ''?'active':''}>{t('ffm.label')}</label>
                </div>
              </div>
              <div className="row">
                <div className={inputs.eo_carrier !== ''? "input-field  camp11 group-fill":"input-field  camp11"} id='eo_carrier'>
                  <input className="input carrier" type="text" name="eo_carrier" id="camp11" onChange={setInput}  defaultValue={inputs.eo_carrier}/>
                  <label htmlFor="camp11" className={inputs.eo_carrier !== ''?'active':''}>{t('compani_seg.label')}</label>
                </div>
                <div className={inputs.eo_pol !== ''? "input-field  camp12 group-fill":"input-field  camp12"} id='eo_pol'>
                  <input className="input pol-num" type="number" name="eo_pol" id="camp12" onChange={setInput}  defaultValue={inputs.eo_pol}/>
                  <label htmlFor="camp12" className={inputs.eo_pol !== ''?'active':''}>{t('num_pol.label')}</label>
                </div>
                <div className={inputs.eo_date !== ''? "input-field  camp13 group-fill":"input-field  camp13"} id='eo_date'>
                  <input className="input datepicker" type="text" name="eo_date" id="camp13" onChange={() => setDatER(this)} onClick={totop} />
                  <label htmlFor="camp13" className={inputs.eo_date !== ''?'active':''}>{t('date_exp.label')}</label>
                  <span className="flechas">
                    <img src="./assets/images/arrow-up.svg" alt="up" />
                    <img src="./assets/images/arrow-down.svg" alt="dw" />
                  </span>
                </div>
              </div>
              <div className="row">
                <div className={inputs.lic_state !== ''? "input-field  camp14  group-fill " + t('lang.label'):"input-field  camp14 " + t('lang.label')}>
                  <Select className='input inp-ciudad slect-tag' id="inp-state" classNamePrefix="react-select" options={states} value={selectstate2} placeholder=" " onChange={setSelectState2}  defaultValue={inputs.lic_state}/>
                  <label htmlFor="camp14" className={inputs.lic_state !== ''?'lb-large active':'lb-large'}>{t('rils.label')}</label>
                </div>
                <div className={inputs.lic_num !== ''? "input-field  camp15 group-fill":"input-field  camp15"}>
                  <input className="input resident" type="text" name="lic_num" id="camp15" onChange={setInput} defaultValue={inputs.lic_num}/>
                  <label htmlFor="camp15" className={inputs.lic_num !== ''?'lb-large active':'lb-large'}>{t('riln.label')}</label>
                </div>
              </div>
              <div className="camp16">
                <p className='p_camp16'>{t('sta_lic.label')}</p>
                <div className="states">
                  {states && states.map((state, i)=>(
                  <label key={state.value} className='checkbox state' htmlFor={state.value+i}>
                    <input id={state.value+i} type='checkbox' name={state.label} defaultValue={state.label} onChange={inserChip} checked={checks.find(ck=>ck===state.label)?true: false}/>
                    <div className="checkmark"></div>
                    <div className='check_text'>
                      {state.label}
                    </div>
                  </label>
                  ))}
                </div>
      
              </div>

              <div className="camp16">
                <p className='p_camp16'>{t('state_w_mark.label')}</p>
                <div className="statesM">
                  {statesMarket && statesMarket.map((stateM, i)=>(
                  <label key={stateM.label} className='checkbox state' htmlFor={stateM.label+i}>
                    <input id={stateM.label+i} type='checkbox' name={stateM.label} defaultValue={stateM.label}  onChange={inserSttMArk} checked={checksM.find(ck=>ck===stateM.label)?true: false}/>
                    <div className="checkmark"></div>
                    <div className='check_text'>
                      {stateM.label}
                    </div>
                  </label>
                  ))}
                </div>
              </div>
              {/*
              <div className="camp16 label-up">
                <label className="label" htmlFor="camp16">States licensed</label>
                <div className="chips chips-autocomplete acord gray-box">
                  <Chips
                    value={chips}
                    onChange={inserChip} 
                    suggestions={suggestions}
                    renderChip={value => 
                      <Chip>{value}</Chip>
                    }
                  />
                </div>
              </div>
              */}
              <div className="uncertified_div">
                <div className="uncertified"> <i className="icon icon-bubble_question"/><p>{t('if_uncertified.label')}</p></div>
                <div className="uncertified_buttons animate__animated animate__backInDown">
                  {statesMarket && statesMarket.map((link, i)=>(
                    <a key={i+'a'} href={link.vinculo} className="button_u">
                    <i className="icon icon-chain"></i>
                    <span>{link.label}</span>
                  </a>
                  ))}
                </div>
                </div>
                <button className="button2" id='boton2' onClick={() => changeStep(3)}>Next</button>
            </div>}
          {step === 3 && 
            <div className="box box3 bo3 animate__animated animate__fadeIn">
              <h5 className='subtitle'>3. {t('attach.label')}</h5>
              <div className="input-filecamp">    
                <div className="camp17">
                  <Upload
                    filesg={files['files1']}
                    setLocalFiles={setLocalFiles}
                    ShutModal={ShutModal}
                    placeholder={t('pl_driv.label')}
                    group={'files1'}
                  />
                </div>
              </div>
              <div className="input-filecamp">    
                <div className="camp17">
                  <Upload
                    filesg={files['files2']}
                    setLocalFiles={setLocalFiles}
                    ShutModal={ShutModal}
                    placeholder={t('pl_e&o.label')}
                    group={'files2'}
                  />
                </div>
              </div>
              <div className="input-filecamp">    
                <div className="camp17">
                  <Upload
                    filesg={files['files3']}
                    setLocalFiles={setLocalFiles}
                    ShutModal={ShutModal}
                    placeholder={t('pl_ffm.label')}
                    group={'files3'}
                  />
                </div>
              </div>
              <div className="input-filecamp">    
                <div className="camp17">
                  <Upload
                    filesg={files['files4']}
                    setLocalFiles={setLocalFiles}   
                    ShutModal={ShutModal}          
                    placeholder={t('pl_mk_certif.label')}
                    group={'files4'}
                  />
                </div>
              </div>
              <div className="input-filecamp">    
                <div className="camp17">
                  <Upload
                    filesg={files['files5']}
                    setLocalFiles={setLocalFiles}    
                    ShutModal={ShutModal}         
                    placeholder={t('pl_all_curr.label')}
                    group={'files5'}
                  />
                </div>
              </div>
              <div className="input-filecamp">    
                <div className="camp17">
                  <Upload
                    filesg={files['files6']}
                    setLocalFiles={setLocalFiles}   
                    ShutModal={ShutModal}          
                    placeholder={t('pl_ahip.label')}
                    group={'files6'}
                  />
                </div>
              </div>
              <button className="button2" id='boton3' onClick={() => changeStep(4)}>{t('next.label')}</button>
            </div>}
          {step === 4 && 
            <div className="box box3 bo4 animate__animated animate__fadeIn">
              <h5 className='subtitle'>4. {t('autorized.label')}</h5>
              <div className="row">
                <div className={inputs.authorization !== ''?'input-field camp18 group-fill':'input-field camp18'} id="authorization">
                  <input className="input full-name" type="text" name="authorization" id="camp18" onChange={setInput} defaultValue={inputs.authorization}/>
                  <label htmlFor="camp18"  className={inputs.authorization !== ''?'active':''}>{t('full_name.label')}</label>
                </div>
                <div className={"input-field camp19 group-fill"}>
                  <input className="input fill" type="text" name="apli_date" disabled id="camp19" value={dates.date_month + ' / ' + dates.date_day + ' / ' + dates.date_year} />
                  <label htmlFor="camp19" className={inputs.apli_date !== ''?'active':''}>{t('date.label')}</label>
                </div>
              </div>
              <div className="text-area">
                <label className="camp20">{t('signature.label')}</label>
                <div className='firma info'>
                  <div className='flx'>
                    <div className='textarea' id='firma-id' >
                      <div className="opt-1">
                        {inputs.signature_type === 'Image' && <img src={inputs.signature_digital} className="img_signa" />}
                        <input className="input" type="file" name="signature" id="fileinput" onChange={getImage} accept=".png, .jpg, .jpeg" />
                      </div>
                      <div className="opt-2 signatur" >
                        <SignatureCanvas penColor='black' canvasProps={{ width: 383, height: 94, className: 'sig-canvas' }} ref={(ref) => { sigPad = ref }} />
                        <div className="sig-buton">
                          <button onClick={clearSig}>{t('clear.label')}</button>
                          <button id="sig-buton-cap" onClick={trim}>{t('save.label')}</button>
                        </div>
                        <span>* {t('pl_save.label')}</span>
                      </div>
                      <div className="opt-3">
                        <textarea placeholder="Firma digital" name="signature_digital" id="text_sign" onChange={setInput}></textarea>
                      </div>
                      <div className="brns">
                        <button className="btn1 brn" onClick={getText} >
                          <i className="icono icon-text"></i>
                        </button>
                        <button className="btn2 brn active" onClick={getSign}>
                          <i className="icono icon-pen"></i>
                        </button>
                        <button className="btn3 brn" onClick={getFile}>
                          <i className="icono icon-camera"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="check-box">
                <label className='checkbox terminos' htmlFor="acepto">
                  <input id='acepto' type='checkbox' name='terms' defaultValue={terms} onClick={() => openModal()} />
                  <div className="checkmark"></div>
                  <div>
                    {t('read&accept.label')}<b>{t('politic.label')}</b>
                  </div>
                </label>
              </div>
                <input className="button2" type="submit" value={t('submit.label')} onClick={submitInputs} />
            </div>}
            </Fragment>
            }
          </div>
  
    <Modal className="modales-n" visible={visible} width="90%" height="80%" effect="fadeInUp">
      <div>
        <Policies />
        <a className="boton-close" onClick={() => closeModal()}>Continuar</a>
      </div>
    </Modal>

    <ModalUplfiles
      files={files[group]}
      setLocalFiles={setLocalFiles}
      placeholder={t('pl_all_curr.label')}
      group={group}
      open={open}
      close={close}
    />
  </div>)

};

export default View;