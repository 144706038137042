import Routes from './router';

// Trasnlation 
import './i18n'
import { useTranslation } from 'react-i18next'

function App() {

  const { i18n } = useTranslation()

  const initLang = () => {
    if (localStorage.getItem('lng')) {
      i18n.changeLanguage(localStorage.getItem('lng'))
    } else {
      window.localStorage.setItem('lng', 'es')
      i18n.changeLanguage('es')
    }
  }

  return (
    <div className="App" onLoad={initLang}>
      <Routes />
    </div>
  );
}

export default App;
